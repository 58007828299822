<template>
  <div class="live">
    <el-container>

      <el-header>
        <div >
          现在在线的云教室系统
        </div>
      </el-header>
      <el-main>
        <el-row :gutter="20" >
          <el-col :span="6" v-for="(o, index) in live" :key="index">
            <el-card :body-style="{ padding: '0px' }" class="card" @click.native="change(o.id)">
              <div class="img">
                <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
                     class="image">
              </div>
              <div style="padding: 14px;">
                <div>{{ o.name }}</div>
                <div class="bottom clearfix">
                  <time class="time">{{ o.date }}</time>
                  <el-button type="text" class="button">操作按钮</el-button>
                </div>
                <div>
                  {{ o.username }}
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>


  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      live:[
        {
          id:"",
          name:"",
          content:"",
        },
        {
          id:"",
          name:"",
          content:"",
        },
        {
          id:"",
          name:"",
          content:"",
        },
        {
          id:"",
          name:"",
          content:"",
        },
        {
          id:"",
          name:"",
          content:"",
        },
        {
          id:"",
          name:"",
          content:"",
        }
      ]
    }
  },methods:{
    change(id){
      console.log(id)
    }
  }
}
</script>

<style scoped>
.live{
  margin: auto;
  width: 70%;
}
.card{
  height: 18.75rem;
  width: 15.625rem;
}
.img{
  height: 11.25rem;
  width: 15.625rem;
}
img{
height: 100%;
  width:100%;
}
</style>
